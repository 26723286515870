/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import ServicesAPI from "@/services/api-services"

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("session_id")) {

    ServicesAPI.methods.apiGET({
      url: 'api/session/verify',
      params: {
        session: localStorage.getItem("session_id")
      }
    }, res => {

      if (res.code == 200) {
        next();
      } else {
        localStorage.removeItem("session_id");
        next('/pages/login');
      }
    });
  } else {
    next('/pages/login');
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/interface/:id_interface',
          name: 'interface',
          component: () => import('./views/Interface.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/model',
          name: 'model',
          component: () => import('./views/Model.vue'),
          beforeEnter: ifAuthenticated,
        }
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/register',
          name: 'pageRegister',
          component: () => import('@/views/pages/Register.vue')
        },
        {
          path: '/pages/login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/error-404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/docs/:interface',
          name: 'pageDocs',
          component: () => import('@/views/pages/Docs.vue')
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
