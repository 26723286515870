<script>
  import axios from "axios";

  //const apiURL = 'https://api-rest-mynor.moshbits.com'; // Mynor
  const apiURL = 'https://api-rest.shopy.com.gt'; // Shopy
  const authAPI = {
    headers: {
      'Content-Type': 'application/json',
      //'authorization': `Basic eEdDVVdOcE5Gb046cEtmcG80amtNb0Y=` // MYNOR
      'authorization': `Basic YXpGMFdHT1llY0pXOmQ4VmwzZTE5R3d4WA==` // Shopy
    }
  };

  const convertGet = (params) => {

    const str = [];

    for (const key of Object.keys(params)) {

      if (typeof (params[key]) !== 'object') {
        str.push(`${key}=${params[key]}`);
      } else if (typeof (params[key]) === 'object' && params[key] !== null) {

        for (const key2 of Object.keys(params[key])) {

          if (typeof (params[key][key2]) !== 'object') {
            str.push(`${key2}=${params[key][key2]}`);
          }
        }
      }
    }

    return str.join('&');
  };

  export default {
    name: 'ServicesAPI',
    methods: {
      apiGET(params, cbk) {
        axios.get(`${apiURL}/${params.url}?${convertGet(params.params)}`, authAPI).then(response => {
          cbk(response.data);
        }).catch(e => {
          console.log(e);
        });
      },
      apiPOST(params, cbk) {
        axios.post(`${apiURL}/${params.url}`, params.params, authAPI).then(response => {
          cbk(response.data);
        }).catch(e => {
          console.log(e);
        });
      },
      apiPUT(params, cbk) {
        axios.put(`${apiURL}/${params.url}`, params, authAPI).then(response => {
          cbk(response.data);
        }).catch(e => {
          console.log(e);
        });
      }
    }
  }
</script>
