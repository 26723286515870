<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import themeConfig from '@/../themeConfig.js'
  import Vue from "vue";

  Vue.mixin({
    methods: {
      uniqid(number) {

        function S4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }

        let string = '';

        for (let i = 0; i < number; i++) {
          string += S4();
        }

        return string;
      }
    }
  });

  export default {
    //mixins: [mixin],
    watch: {
      '$store.state.theme'(val) {
        this.toggleClassInBody(val);
      }
    },
    async created() {
      /*try {
        await this.$auth.renewTokens();
      } catch (e) {
        console.log(e);
      }*/
    },
    methods: {
      toggleClassInBody(className) {
        if (className == 'dark') {
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
          document.body.classList.add('theme-dark');
        } else if (className == 'semi-dark') {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
          document.body.classList.add('theme-semi-dark');
        } else {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        }
      },
      handleWindowResize(event) {
        this.$store.dispatch('updateWindowWidth', event.currentTarget.innerWidth);
      },
    },
    mounted() {
      this.toggleClassInBody(themeConfig.theme)
      this.$nextTick(() => {
        window.addEventListener('resize', this.handleWindowResize);
      });
      this.$store.dispatch('updateWindowWidth', window.innerWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
  }

</script>

<style>
  .pointer {
    cursor: pointer;
  }
</style>
